import type { Ref } from "vue";
import { ref } from "vue";
import { useTeleport } from "./elements";

interface DropDownMenuData {
  top: Ref<number>;
  left: Ref<number>;
  right: Ref<number>;
  showMenu: Ref<boolean>;
  toggleMenu: () => void;
  closeMenu: ({ afterClose }: { afterClose: () => void }) => void;
  menuActivator: Ref<HTMLElement | null>;
}

export const useDropdownMenu = (
  scrollable: string,
  horizontalScrollable?: string
): DropDownMenuData => {
  const showMenu = ref<boolean>(false);
  const menuActivator = ref<HTMLElement | null>(null);

  const { top, left, right } = useTeleport(
    menuActivator,
    scrollable,
    "menu",
    menuActivator,
    horizontalScrollable
  );

  const toggleMenu = () => {
    if (!showMenu.value) {
      document.body.click();
    }
    showMenu.value = !showMenu.value;
  };
  const closeMenu = ({ afterClose }: { afterClose: () => void }): void => {
    showMenu.value = false;

    afterClose?.();
  };

  return {
    showMenu,
    menuActivator,
    toggleMenu,
    closeMenu,
    top,
    left,
    right
  };
};
