<template>
  <div class="flex h-6">
    <button
      type="button"
      v-on-click-outside="closeMenu"
      @click.stop="toggleMenu"
      ref="menuActivator"
      class="flex relative pl-2 py-0-5 text-left text-s rounded-full items-center font-bold"
      :class="[
        model
          ? 'bg-green-400 bg-opacity-20 text-green-400'
          : 'bg-gray-100 text-gray-300',
        { 'cursor-not-allowed': disabled }
      ]"
    >
      {{ OPTIONS[selectedVal] }}
      <span class="ml-2 inset-y-0 flex place-self-center pr-2 cursor-pointer">
        <icon-base
          height="6"
          width="10"
          icon="dropdown-arrow"
          :iconColor="model ? '#93E7C9' : '#D1D5DB'"
        />
      </span>
    </button>

    <teleport to="#menus">
      <div
        v-if="!disabled && showMenu"
        class="absolute mt-7"
        :style="{
          top: `${top - 25}px`,
          left: `${left}px`
        }"
      >
        <ul
          class="z-50 absolute sm:w-40 bg-white shadow-lg rounded-md py-2 text-sm text-gray-500 sm:text-sm"
        >
          <li
            v-for="(val, index) in OPTIONS"
            :key="index"
            class="py-2 pr-2 hover:bg-gray-200 flex justify-start overflow-auto"
            role="option"
            @click.stop="
              closeMenu({ afterClose: () => selectPlacementMethod(index) })
            "
          >
            <span class="ml-3 font-normal cursor-pointer">{{ val }}</span>
          </li>
        </ul>
      </div>
    </teleport>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { useDropdownMenu } from "@/hooks/menus";
import { useI18n } from "vue-i18n";

const props = defineProps({
  model: {
    type: Boolean
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const { dispatch } = useStore();
const { t } = useI18n();
const selectedVal = computed(() => (props.model ? 1 : 0));
const OPTIONS = [t("COMMON.INCOMPLETE"), t("COMMON.COMPLETE")];

const { top, left, menuActivator, showMenu, toggleMenu, closeMenu } =
  useDropdownMenu("dealsWrapper");

const selectPlacementMethod = async (placementMethod: number) => {
  dispatch("applications/setDiscoveryStatus", placementMethod);
};
</script>
